/** @jsx jsx */
import { jsx, Theme, ColorMode } from "theme-ui";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTable, Column, HeaderGroup, Row, Cell } from "react-table";

import { Placeholder } from "./styles";
import { formatCurrency } from "utils";

interface tableProps {
  columns: Column<{}>[];
  data: {}[];
  placeholder?: string;
  showTotal?: boolean;
  total?: number;
}

export const FormTable = ({
  columns,
  data,
  placeholder,
  showTotal,
  total
}: tableProps) => {
  const memoizedColumns = React.useMemo(() => columns, [columns]);
  const memoizedData = React.useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable({
    columns: memoizedColumns,
    data: memoizedData
  });

  return (
    <FormTableWrapper>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={uuidv4()}>
              {headerGroup.headers.map(column => (
                <th
                  style={{
                    width: column.width
                  }}
                  {...column.getHeaderProps()}
                  key={column.id}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: Row<{}>) => {
            prepareRow(row);
            return (
              <React.Fragment key={uuidv4()}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: Cell) => {
                    return (
                      <td {...cell.getCellProps()} key={uuidv4()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
          {showTotal && data.length ? (
            <tr className="total">
              <td colSpan={7}></td>
              <td>Total: </td>
              <td>{formatCurrency(total)}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
      {!data.length && (
        <Placeholder>
          <p>{placeholder}</p>
        </Placeholder>
      )}
    </FormTableWrapper>
  );
};

interface themeProps extends Theme {
  colors: ColorMode;
}

const FormTableWrapper = styled.div`
  ${({ theme }: { theme?: themeProps }) => css`
    overflow-x: scroll;
    height: 200px;
    margin-left: 150px;
      
    background-color: ${theme?.colors.highlight};

    table {
      width: 100%;

      tbody {
        tr {
          background-color: ${theme?.colors.highlight};
        }
      }
    }

    .total {
      background-color: ${theme?.colors.highlight};
    }
  `}
`;
